@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300&display=swap');

.app {
  font-family: 'Roboto', sans-serif;
}

.title {
  text-align: center;
  font-size: 34px;
  margin: 80px 30px 40px;  
  font-weight: 100;
  padding: 20px;
  @media screen and (max-width: 600px) {
    font-size: 28px;
    margin: 40px 30px 30px;  
    
  }
}

.info {
  text-align: center;
  margin: 100px auto 0;
  padding: 0 20px;
  @media screen and (max-width: 600px) {
    margin-top: 60px;
  }
}

.select {
  margin: 30px auto 40px;
  max-width: 600px;
  padding: 0 20px;
  .is-empty & {
    margin-bottom: 200px;
    @media screen and (max-width: 600px) {
      margin-bottom: 60px;
    }

  }
}

.chart {
  margin: 40px auto;
}

.logo-link {
  display: block;
  margin: 40px auto;
  text-decoration: none;
}

.logo {
  width: 200px;
  display: block;
  height: auto;
  margin: 40px auto;
}
